import React from "react";
import "../style/bfooter.scss";

const B_footer = () => {
  return (
    <>
      <div className="bfooter-parent parent">
        <div className="overlay"></div>
        <div className="bfooter-cont container">
          <h3 className="main-content">Explore Your Next Adventure</h3>
          <p className="sub-con">
            Discover Unforgettable Journeys with Travel and Taste
          </p>
          <a
            href="https://travelandtaste.com.au"
            target="_blank"
            className="btn"
          >
            Visit Our Main Website
          </a>
        </div>
      </div>
    </>
  );
};

export default B_footer;
