import React, { useEffect, useState } from 'react'
import '../style/header.scss';
import { Link } from 'react-router-dom';
import Modal from './Modal/Modal';

function Header() {
  const [modal, setModal] = useState(false);

  function toggle() {
    setModal(!modal);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {modal && <Modal close={toggle} />}
    <div className="header-parent parent">
    <div className="header-container container">
     <div className="logo-box">
        <div className="logo-image bg-img-contain"></div>
        </div> 

       <div className="contact-btn"> 
        <a href="#contact" className='btn' >Enquire Now</a>
        </div>

    </div>

    </div>
    </>
  )
}

export default Header