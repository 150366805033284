import React, { useState } from 'react'
import '../style/about.scss';
import { Link } from 'react-router-dom';
import Accordian from './Accordian';

function About() {

    const [serviceSupport, setServiceSupport] = useState(null);
    const handleSupport = (index) => {
        setServiceSupport(index === serviceSupport ? null : index);
    };


    const accrodianData = [
        {
            id: 1,
            heading: "Arrival in Kathmandu",
            content: "Upon your arrival in Kathmandu, our representative will be waiting for you outside the arrival terminal building to welcome you and facilitate your transfer to the hotel."
        },
        {
            id: 2,
            heading: "Welcome Briefing",
            content: "At the hotel, you will receive a welcome briefing that includes an overview of the trekking itinerary, safety protocols, and a Q&A session to address any concerns or questions."
        },

        {
            id: 3,
            heading: "Acclimatization Day",
            content: "The following day, you will have time to acclimatize in Kathmandu. This is crucial for adjusting to the altitude. You can explore local attractions, visit historic sites, or simply relax at the hotel."
        },
        {
            id: 4,
            heading: "Gear Check and Preparation",
            content: "We will conduct a thorough gear check to ensure you have all the necessary equipment for the trek. Any missing items can be purchased or rented from local shops."
        },
        {
            id: 5,
            heading: "Cultural Immersion",
            content: "Experience the rich culture of Kathmandu with a guided tour of UNESCO World Heritage Sites such as Swayambhunath (Monkey Temple), Boudhanath Stupa, and Pashupatinath Temple."
        },
    ]
    return (
        <>

            <div className="april-parent parent">
                <div className="bg-overlay bg-img-cover"></div>
                {/* <div className="april-container container">
                    <div className="heding-div">
                        <h2 className='section-heading'>The Everest Base Camp</h2>
                    </div>

                    <div className="two-box">
                        <div className="april-left">

                            <div className="april-left-text">
                                <h3 className='sub-heading'>1st Trekkers Group</h3>
                                <p className='para-p'>Arrival in Kathmandu. Upon arrival, our representative will meet outside the arrival terminal building and transfer to Hotel.</p>
                                <Link className='btn'>15th April 2025</Link>
                            </div>
                      
                        </div>

                        <div className="april-right">
                            <div className="april-right-img-box">
                                <div className="april-right-img bg-img-cover"></div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* -------------------------May section------------------- */}


                <div className="may-container container">

                    <div className="heding-div">
                        <h2 className='section-heading'>The Everest Base Camp</h2>
                    </div>
                    <div className="two-box">
                        <div className="may-left">
                            <div className="may-left-img-box">
                                <div className="may-left-img bg-img-cover"></div>
                            </div>

                        </div>

                        <div className="may-right">
                            <div className="right-top">    {accrodianData.map((item, index) => (
                                <Accordian
                                    key={item.id}
                                    index={index}
                                    heading={item.heading}
                                    content={item.content}
                                    isActive={serviceSupport === index}
                                    onAccordionClick={handleSupport}
                                />
                            ))}</div>
                            <div className="right-bottom">
                                <div className="rb1">
                                    <h4 className='exp-date'>Expedition 1 </h4>
                                    <p className='t-date'>15th April 2025</p>
                                </div>
                                
                                <div className="rb2">
                                    <h4 className='exp-date'>Expedition 2 </h4>
                                    <p className='t-date'>1st May 2025</p>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default About