import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';

import '../style/hero.scss';


function Hero() {
    return (
        <>
            <div className="hero-parent parent">
                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="overlay"></div>
                        <div className="slide-content">
                            <h2 className='slide-headding'>Adventure Awaits at Everest Base Camp</h2>
                            <p className='slide-p'>Embrace the Himalayas with Our New Everest Base Camp Journey</p>
                        </div>
                        <div className="swiper-slide s-img1 bg-img-cover">

                        </div>

                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="overlay"></div>
                        <div className="slide-content">
                            <h2 className='slide-headding'>Step into Majesty</h2>
                            <p className='slide-p'>Witness Everest Base Camp with Our Expert-Led Trek</p>
                        </div>
                        <div className="swiper-slide s-img2 bg-img-cover">

                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="overlay"></div>
                        <div className="slide-content">
                            <h2 className='slide-headding'>Unleash Your Inner Explorer</h2>
                            <p className='slide-p'>Discover Everest Base Camp in 2025</p>
                        </div>
                        <div className="swiper-slide s-img3 bg-img-cover">

                        </div>
                    </SwiperSlide>
                </Swiper>

            </div>

        </>
    )
}

export default Hero