import React, { useState } from 'react'
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import "../style/accordian.scss"
function Accordian(props) {
    const [ accordian, setAccordian] = useState(false)
    return (
        <>
           <div className="accordian">
            <div className="heading" onClick={()=>props.onAccordionClick(props.index)}>
                <h4> {props.heading} </h4>
                <span  className='acc-icon' >
               {!props.isActive ?  <IoIosArrowDown /> :
                <IoIosArrowUp />}
                </span>
            </div >
       { props.isActive &&    <div className="content">
                <p>
               {props.content}
                </p>
            </div>}
           </div>
        </>
    )
}

export default Accordian


