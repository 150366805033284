import React, { useRef, useState } from 'react';
import Contact from '../Contact';
import { GrClose } from 'react-icons/gr';
import '../../style/model.scss';
import { notification } from 'antd';

function Modal() {

  const formRef = useRef(null);
  const [status, setStatus] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    fetch(
      "https://script.google.com/macros/s/AKfycbwUkLkYqKic7KnQjqXgjzF-1TlYm8zJNt7IFFX-C2ZlTL2lloye8cgWaiiBaH1KDECgoA/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        res.json(); formRef.current.reset();
        notification.success({ "message": "successfully uploaded" });
      })
      .catch((error) => {
        notification.error({ "message": "Something went wrong" });
      });

  }


  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-parent parent">

          <div className="pop-box container">

            <div className="close" onClick={closeModal}>
              <GrClose />
            </div>

            <div className="contact-right">
              {/* <h2 className='contact-headding'>Begin Your<br /> Himalayan Quest</h2> */}

              <div className="pop-img-box ">
                <h2 className='contact-headding'>Begin Your<br /> Himalayan Quest</h2>
                <div className="pop-overlay"></div>
                <div className="pop-img bg-img-cover"></div>
              </div>

              <form ref={formRef} className="form" onSubmit={handleSubmit}>
                <div className="input-div">
                  <input
                    required
                    type="text"
                    name="Name"
                    placeholder='Name'
                  />
                </div>
                <div className="input-div">
                  <input
                    required
                    type="number"
                    name="Number"
                    placeholder='Number'
                  />
                </div>

                <div className="input-div">
                  <input
                    required
                    type="email"
                    name="Email"
                    placeholder='Email'
                  />
                </div>

                {/* <div className="input-div">
                  <input
                    required
                    type="text"
                    name="Current_City"
                    placeholder='Current City'
                  />
                </div> */}
                {/* <div className="input-div">
                  <select required name="Package">
                    <option value="" disabled selected>Choose Package</option>
                    <option value="basic">Basic</option>
                    <option value="standard">Standard</option>
                    <option value="premium">Premium</option>
                  </select>
                </div> */}
                <div className="input-div">
                  <textarea
                    name="Message"
                    id=""
                    cols="46"
                    rows="2"
                    placeholder='Message'
                  ></textarea>
                </div>

                <div className="btn-div">
                  <button type="submit" className="btn">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

      )}
    </>
  );
}

export default Modal;