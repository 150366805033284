import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './componant/Header';
import Hero from './componant/Hero';
import About from './componant/About';
import Footer from './componant/Footer';
import B_footer from './componant/B_footer';
import Contact from './componant/Contact';
import Galleryswiper from './componant/Galleryswiper';
import Accordian from './componant/Accordian';


function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Header />
        <Hero />
        <About />

        <Galleryswiper/>
        <Contact />
        <B_footer />
        <Routes>
          {/* <Route></Route> */}
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
      <Footer />

    </div>
  );
}

export default App;
