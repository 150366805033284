import React, { useState } from "react";
import "../style/footer.scss";
import { IoLogoFacebook } from "react-icons/io5";
import { AiFillTwitterCircle } from "react-icons/ai";
import Award2 from "../Assets/1.webp";
import Award3 from "../Assets/2.webp";
import Award4 from "../Assets/3.webp";
import Award5 from "../Assets/4.webp";
import Award6 from "../Assets/5.webp";
import Award7 from "../Assets/6.png";
import Award1 from "../Assets/trophy.png";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { MdOutlineCopyright } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <div className="footer-parent parent">
        <div className="footer-cont">
          <h2 className="title">Our Awards </h2>
          <div className="award">
            <div className="img-list">
              <div className="main-trophy">
                <img src={Award1} alt="Award 1" />
              </div>

              <div className="award-group">
                <img src={Award2} alt="Award 2" />

                <img src={Award3} alt="Award 3" />
              </div>
              <div className="award-group">
                <img src={Award7} alt="Award 7" />
                <img src={Award4} alt="Award 4" />
              </div>
              <div className="award-group">
                <img src={Award5} alt="Award 5" />
                <img src={Award6} alt="Award 6" />
              </div>
              <div className="main-trophy">
                <img src={Award1} alt="Award 1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer parent">
        <div className="bottom-footer-cont container">
          <div className="email">
            <IoIosMail />
            <a href=""> rohit@travelandtaste.com.au</a>
          </div>
          <div className="copyright">
            <p> <span className="copyright-icon"><MdOutlineCopyright/></span>2024 By Travel And Taste</p>
          </div>
          <div className="icons">
            Follow Us!
            <a
              className="icon"
              href="https://www.facebook.com/travelandtaste.au/"
              target="_blank"
            >
              <IoLogoFacebook />
            </a>
            <a
              className="icon"
              href="https://x.com/Travel_andtaste"
              target="_blank"
            >
              <AiFillTwitterCircle />
            </a>
            <a
              className="icon"
              href="https://www.instagram.com/travel_and_taste/"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              className="icon"
              href="https://www.linkedin.com/company/travel-and-taste1/"
              target="_blank"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
