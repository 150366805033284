import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '../style/galleryswiper.scss';

function Galleryswiper() {
    return (
        <>
            <div className="gallery-parent parent">
                {/* <div className="g-overlay"></div> */}
                <h2 className='section-heading'>Memories From The Trip</h2>
                <div className="gallery-container container">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={4}
                        centeredSlides={false}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        breakpoints={{


                            350: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },

                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            910: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                            },

                            1159: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                            1160: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            // 1024: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 20,
                            // },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                            },
                        }}
                        modules={[Autoplay,  Navigation]}
                        className="mySwiper2"
                    >
                        <div className="swiper-slides-box">


                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img1  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img2  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img3  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img4  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img5  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img6  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img7  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img8  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img9  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="g-img-box">
                                    <div className=" g-img g-img10  bg-img-cover"></div>
                                </div>
                            </SwiperSlide>



                        </div>

                    </Swiper>

                </div>
            </div>

        </>
    )
}

export default Galleryswiper