import React, { useRef, useState } from "react";
import "../style/contact.scss";
import { notification } from "antd";

const Contact = () => {
  const formRef = useRef(null);
  const [status, setStatus] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    fetch(
      "https://script.google.com/macros/s/AKfycbwUkLkYqKic7KnQjqXgjzF-1TlYm8zJNt7IFFX-C2ZlTL2lloye8cgWaiiBaH1KDECgoA/exec",
      {
        method: "POST",
        body: formData,
      }
    )
    .then((res) =>{ res.json(); formRef.current.reset();
      notification.success({"message":"Send Successfully"});})
    .catch((error) => {
      notification.error({"message":"Something went wrong"});
      });
  }

  return (
    <div className="contact-parent parent" id="contact">
      <div className="contact-bg"></div>
      <div className="contact-cont container">
        <div className="left">
          <h2 className="title">Begin Your Himalayan Quest</h2>

          <form className="form-data" ref={formRef} onSubmit={handleSubmit}>
            <input required type="text" className="input-data" placeholder="Name" name="Name" />
            <input required type="number" className="input-data" placeholder="Number" name="Number" />
            <input required type="email" className="input-data" placeholder="Email" name="Email" />
            <input
              required
              type="text"
              className="input-data"
              name="Current_City"
              placeholder="Current City"
            />
            {/* <select required name="Package" className="input-data">
              <option value="" disabled>Choose Package</option>
              <option value="basic">Basic</option>
              <option value="standard">Standard</option>
              <option value="premium">Premium</option>
            </select> */}
            <textarea
              className="input-data"
              rows="4"
              cols="50"
              name="Message"
              placeholder="Message"
            ></textarea>
            <div className="btns">
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
            {status && <p>{status}</p>}
          </form>
        </div>
        <div className="right">
          <div className="right-bg"></div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
